<template>
  <div id="print-messenger">
    <div class="black-strip">
      <span class="letter">L</span>
      <span class="letter">L</span>
      <span class="letter">U</span>
      <span class="letter">P</span>
    </div>
    <div class="custom-table template">
      <table>
        <tr>
          <td class="left-top">
            <span style="font-size: 26px"
              >ผู้รับ: คุณ {{ member.first_name }} {{ member.last_name }}
              <br />
              โทร: {{ member.phone | phone }}</span
            >
          </td>
          <td class="right" rowspan="2">
            <img src="@/assets/images/admin/cutter-logo.png" />
          </td>
        </tr>
        <tr>
          <td class="left-bottom">
            <div class="qr-and-text">
              <QRCode :text="order.order_id" :size="80" />
              <div class="info-text">
                <p style="font-size: 1.2em">{{ order.order_id }}</p>
                <p>{{ order.start_date | date }}</p>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="black-strip" style="margin-top: 1px">
      <span class="letter">ใบส่งคืน</span>
    </div>
    <div class="custom-table template">
      <table>
        <tr>
          <td class="left-top">
            [ข้อมูลส่งคืน] <br />
            - ชื่อผู้รับ : ร้าน Lenslineup <br />
            - เบอร์โทรผู้รับ : 080-818-8873 <br />
            - แจ้ง Rider ส่งที่ชั้น 12 ไม่มีคนรับด้านล่าง <br />
            - ร้านเปิด จันทร์-เสาร์ 11:30 - 19:00 , อาทิตย์ 11:30 - 14:00
            นอกเวลาไม่มีคนรับคืน<br />
            *** ห้ามส่งผ่าน Bolt ***
            <hr />
            ร้าน Lenslineup ชั้น 12 ห้อง 12C อาคารเอเชีย <br />
            หน้าตึกมีเซเว่น ติด BTS ราชเทวี <br />
            โทร 080-818-8872 ถึง 74 , 02-611-0695 <br />
          </td>
          <td class="right">
            <div class="qr-center" style="margin-top: 20px">
              <QRCode :text="order.order_id" :size="120" /> <br />
            </div>
            <div class="qr-center">
              <p style="font-size: 1.2em">{{ order.order_id }}</p>
            </div>
            <hr />
            <div class="qr-center" style="margin-top: 20px">
              <p>วันที่คืน</p>
            </div>
            <div class="qr-center">
              <p>{{ order.end_date | date }}</p>
            </div>
            <div class="qr-center">
              <p>ก่อนเวลา {{ order.end_date | time }}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";

import moment from "moment";
export default {
  name: "OrderMessengerPrint",
  mounted() {
    // Keep your logic to remove unwanted body classes
    document.body.classList.remove("dark-layout");
    const toastContainers = document.querySelectorAll(
      ".Vue-Toastification__container"
    );
    toastContainers.forEach((container) => {
      container.parentNode.removeChild(container);
    });
    this.load_order();
  },
  components: {
    QRCode: VueQRCodeComponent,
  },
  methods: {
    print() {
      const printContent = document.getElementById("print-messenger").outerHTML;
      const originalContent = document.body.innerHTML;

      // Replace body content with only the print content
      document.body.innerHTML = printContent;
      window.print();

      // Restore original content after printing
      document.body.innerHTML = originalContent;
      window.location.reload(); // Ensure Vue app state is restored
    },
    async load_order() {
      await this.$http({
        method: "GET",
        url: `/order/print/${this.$route.params.id}`,
      })
        .then((x) => {
          this.order = x.data.data;
          this.member = this.order.member;
        })
        .catch((e) => {
          // this.$router.push({name:'order-list'})
          console.log("error", e);
        });
    },
  },
  data() {
    return {
      order: {
        order_id: "tt",
      },
      member: {},
    };
  },
  filters: {
    phone: function (phone) {
      if (phone) {
        phone = phone.replace(/\D/g, "");
        return phone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
      }
      return "";
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    date: function (date) {
      return moment(date).format("D-M-YYYY");
    },
  },
};
</script>

<style scoped>
/* Reset all styles */
* {
  all: unset;
  box-sizing: border-box;
}

p {
  display: block; /* Force paragraph back to block behavior */
  margin: 0; /* or some margin if you like, e.g. 1em 0 */
  padding: 0;
}

.black-strip {
  width: 100%; /* Full width */
  background: black; /* Black background */
  color: white; /* White text */
  text-align: center; /* Center the text */
  font-size: 48pt; /* Large font size */
  font-weight: bold; /* Bold text */
  padding: 10px 0; /* Add spacing around the text */
  /* margin-bottom: 20px;  */
}

/* Black Strip Styling */
.black-strip {
  width: 100%; /* Full width */
  background: black; /* Black background */
  color: white; /* White text */
  display: flex; /* Flexbox for text alignment */
  justify-content: space-around; /* Space between letters */
  align-items: center; /* Center vertically */
  font-size: 48pt; /* Large font size */
  font-weight: bold; /* Bold text */
  padding: 20px 0; /* Add spacing around the text */
  /* margin-bottom: 20px;  */
}

.qr-and-text {
  display: flex; /* side-by-side layout */
  align-items: center;
  gap: 10px;
}

.info-text {
  display: block; /* ensure it remains a block container */
  margin-left: 10px; /* space between QR code and text */
}

/* .letter {
  margin: 0 20px;
} */

.letter {
  margin: 0 10px;
}
/* Main container: Full width and height */
#print-messenger {
  /* width: 100%; 
  height: 100%; 
  background: white; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black; 
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; */
  display: block;
  margin: 0;
  padding: 0;
  border: 2px solid black; /* Keep border visible */
  page-break-before: auto; /* Prevent splitting */
  page-break-after: avoid; /* Avoid spilling onto another page */
  /* width: 100%; 
  height: 100%;  */
}

/* Content styling */
.template {
  /* text-align: center;
  font-size: 14pt;
  line-height: 1.5;
  color: black; */
  display: block;
  font-size: 14pt;
  line-height: 1.5;
  color: black;
}
.row {
  display: flex;
  width: 100%;
}

.col {
  flex: 1;
  padding: 16px;
  text-align: left;
}

/* table */
.custom-table table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}

.custom-table tr {
  display: table-row;
}

.custom-table td {
  display: table-cell;
  border: 5px solid black;
  vertical-align: top; /* place content at the top of the cell */
  padding: 15px; /* reintroduce some spacing */
}
.qr-center {
  display: flex;
  justify-content: center; /* horizontal center */
  align-items: center; /* vertical center */
  height: 100%; /* if you want it vertically centered within a taller cell */
}

.left-top,
.left-bottom {
  width: 75%;
}
.right {
  width: 25%;
  padding: 0px !important;
}
.right img {
  display: block; /* reintroduce block or inline-block if needed */
  max-width: 100%; /* or any width you prefer */
  height: auto; /* keep aspect ratio */
  padding: 0;
}

hr {
  display: block; /* ensure it occupies a block line */
  border: none; /* reset any existing border */
  width: 100%; /* span full width (adjust if desired) */
  height: 2px; /* line thickness */
  background-color: black; /* line color */
  margin: 8px 0; /* optional spacing above/below */
}

/* Print-specific styles */
@media print {
  body {
    margin: 0 !important; /* Remove browser-added margins */
    padding: 0 !important; /* Remove browser-added padding */
    background: none !important; /* Ensure no background */
    width: 100%; /* Full width of the page */
    height: 100%; /* Full height of the page */
    overflow: hidden; /* Prevent content overflow */
    -webkit-print-color-adjust: exact; /* Ensure accurate colors in Safari */
  }
  .custom-table table {
    width: 15cm;
    margin: 0; /* Center horizontally on the page */
  }

  #app {
    display: none !important; /* Hide all other app content */
  }

  .black-strip {
    width: 15cm;
    padding: 10px 0;
  }

  #print-messenger {
    display: block;
    margin: 0;
    padding: 0;
    border: 2px solid black; /* Keep border visible */
    page-break-before: auto; /* Prevent splitting */
    page-break-after: avoid; /* Avoid spilling onto another page */
    width: 100%; /* Full width for printing */
    height: 100%; /* Full height for printing */
  }
}

@page {
  size: auto; /* Use the printer's paper size */
  margin: 0; /* Remove all margins */
}
</style>
